export const environment = {
    production: false,
    defaultLang: 'en',
    mocked: false,
    here: {
      apikey: 'UOQuu1alZ6gXYBKHBslj4PIV6I6Yi2v2DKEyOfY8', //TODO: Rimuovere chiave Mapbox prima di rilasciare
    },
    backend: {
      apikey: 'UOQuu1alZ6gXYBKHBslj4PIV6I6Yi2v2DKEyOfY8',
      baseURL: 'https://telematics.fcl-02.prep.intra.fcagcv.com',
      baseURLToken: 'https://identity.fcl-02.prep.intra.fcagcv.com',
      token: '',
      tokenRefresh: 'Basic N2FrcDhlM2U4bm9yNXIzNmphcjM0dGlrdDoxamR1Mm1yZGwwOGk0dG1yMjRiNG9sMTZwOWdic2oxazE1dHZnb2xibTE2ZnZudXI3c3Rk',
      xsrfToken: 'XSRF-TOKEN=cfbc8f0d-b4d4-492c-a5c3-bf900db6a0fe'
    },
  };