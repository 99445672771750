<div class="snackbar-container">
  <div [class]="fillerClass"></div>
  <div [class]="contentContainerClass">
    <div class="snackbar-logo-container">
      <img class="snackbar-logo" src="../../assets/images/logoStellantisBig.svg" />
      <button
        *ngIf="snackbarService.getSnackbarConfigObject()?.snackbarType === 'success'"
        class="snackbar-cancelIconButton"
        (click)="openCancel()"
      >
        &#215;
      </button>
    </div>
    <div class="snackbar-dynamic-content-container">
      <div [class]="messageHeaderClass" *ngIf="snackbarService.getSnackbarConfigObject()">
        {{ snackbarService.getSnackbarConfigObject()!.messageHeader | translate}}
      </div>
      <div class="snackbar-message-content" *ngIf="snackbarService.getSnackbarConfigObject()">
        {{ snackbarService.getSnackbarConfigObject()!.messageContent | translate}}
      </div>
      <div
        *ngIf="snackbarService.getSnackbarConfigObject()?.snackbarType !== 'success'"
        class="snackbar-button-container"
      >
        <s-button
          [cssClass]="cancelSButtonClass"
          label="{{ cancelActionName | translate | titlecase}}"
          type="flat"
          [disabled]="false"
          [isSuffix]="false"
          (clickEvent)="openCancel()"
        ></s-button>
        <s-button
          [cssClass]="confirmSButtonClass"
          label="{{ confirmActionName | translate| titlecase }}"
          type="flat"
          [disabled]="false"
          [isSuffix]="false"
          (clickEvent)="openConfirm(snackbarService.getSnackbarConfigObject()?.messageHeader)"
        ></s-button>
      </div>
    </div>
  </div>
</div>
