import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { SpinnerService } from '../../components/spinner/spinner.service';
import { MockWebService } from './mock-web.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WebService {

  testDomain = "d3ag8pu23f8e3t.cloudfront.net"
  
  BASE_URL = environment.backend.baseURL;
  BASE_URL_TOKEN = environment.backend.baseURLToken;
  API_KEY = environment.backend.apikey;
  X_API_KEY = environment.backend.apikey;
  /* ACCESS_TOKEN = '' */
  TOKEN_REFRESH_AUTHORIZATION = environment.backend.tokenRefresh;
  XSRF_TOKEN = environment.backend.xsrfToken;

  ACCESS_CONTROL_HEADER_ORIGINS = "https://d3ag8pu23f8e3t.cloudfront.net"

  constructor(private readonly http: HttpClient, private readonly spinnerService: SpinnerService) { }

  /**
   * GET Service
   * @param serviceUrl string
   * @param params HttpParams
   * @returns Promise<any>
   */
  public async getService(serviceUrl: string, params?: HttpParams, inputHeaders?: [{key: string, value:string}], isMocked = environment.mocked): Promise<any> {
    if (isMocked) {
      return new MockWebService(this.http, this.spinnerService).getMockResponse(serviceUrl);
    }

    this.spinnerService.setIsSpinnerActive(true);

    let url: string = this.BASE_URL;
    if (serviceUrl) {
      url = url.concat(serviceUrl);
    }

    //@ts-ignore
    let headers = new HttpHeaders({ Authorization: localStorage.getItem("ACCESS_TOKEN") || '' });
    //headers = headers.append("api-key", this.API_KEY);
    headers = headers.append("x-api-key", this.X_API_KEY);
    //headers = headers.append("Access-Control-Allow-Origin", this.ACCESS_CONTROL_HEADER_ORIGINS)
    if (inputHeaders) {
      inputHeaders.forEach((inputHeader: {key: string, value: string}) => {
        headers = headers.append(inputHeader.key, inputHeader.value)
      })
    }

    const source$ = this.http.get<any>(url, {params, headers, observe: 'response'}).pipe(
      tap(() => this.spinnerService.setIsSpinnerActive(false)),
      catchError((err: HttpErrorResponse) => {
        this.spinnerService.setIsSpinnerActive(false);
        throw err;
      })
    )
    const res: HttpResponse<any> = await lastValueFrom(source$);
    return res.body
  }

  /**
   * POST Service
   * @param serviceUrl string
   * @param body any
   * @returns Promise<any>
   */
  public async postService(serviceUrl: string, inputHeaders?: [{key: string, value:string}], body?: any, customBaseURL?: string, isMocked = environment.mocked): Promise<any> {
    if (isMocked) {
      return new MockWebService(this.http, this.spinnerService).getMockResponse(serviceUrl);
    }

    this.spinnerService.setIsSpinnerActive(true);

    let url: string = customBaseURL ? customBaseURL : this.BASE_URL;
    if (serviceUrl) {
      url = url.concat(serviceUrl);
    }

    const bodyRequest = typeof body === 'string' ? body : JSON.stringify(body);
    
    //@ts-ignore
    let headers = new HttpHeaders({ Authorization: localStorage.getItem("ACCESS_TOKEN") || '' });
    //headers = headers.append("api-key", this.API_KEY);
    headers = headers.append("x-api-key", this.X_API_KEY);
    headers = headers.append("Content-Type", "application/json");
    //headers = headers.append("Access-Control-Allow-Origin", this.ACCESS_CONTROL_HEADER_ORIGINS)
    if (inputHeaders) {
      inputHeaders.forEach((inputHeader: {key: string, value: string}) => {
        headers = headers.append(inputHeader.key, inputHeader.value)
      })
    }


    const source$ = this.http.post<any>(url, bodyRequest, { headers: headers }).pipe(
      tap(() => this.spinnerService.setIsSpinnerActive(false)),
      catchError((err: HttpErrorResponse) => {
        this.spinnerService.setIsSpinnerActive(false);
        throw err;
      })
    )
    const res: HttpResponse<any> = await lastValueFrom(source$);
    return res?.body
  }

    /**
   * PUT Service
   * @param serviceUrl string
   * @param body any
   * @returns Promise<any>
   */
    public async putService(serviceUrl: string, inputHeaders?: [{key: string, value:string}], body?: any, customBaseURL?: string, isMocked = environment.mocked): Promise<any> {
      //TODO: UPDATE not working
      if (isMocked) {
        return new MockWebService(this.http, this.spinnerService).getMockResponse(serviceUrl);
      }
  
      this.spinnerService.setIsSpinnerActive(true);
  
      let url: string = customBaseURL ? customBaseURL : this.BASE_URL;
      if (serviceUrl) {
        url = url.concat(serviceUrl);
      }
  
      const bodyRequest = typeof body === 'string' ? body : JSON.stringify(body);
      
      //@ts-ignore
      let headers = new HttpHeaders({ Authorization: localStorage.getItem("ACCESS_TOKEN") || '' });
      //headers = headers.append("api-key", this.API_KEY);
      headers = headers.append("x-api-key", this.X_API_KEY);
      headers = headers.append("Content-Type", "application/json");
      //headers = headers.append("Access-Control-Allow-Origin", this.ACCESS_CONTROL_HEADER_ORIGINS)
      if (inputHeaders) {
        inputHeaders.forEach((inputHeader: {key: string, value: string}) => {
          headers = headers.append(inputHeader.key, inputHeader.value)
        })
      }
  
  
      const source$ = this.http.put<any>(url, bodyRequest, { headers: headers }).pipe(
        tap(() => this.spinnerService.setIsSpinnerActive(false)),
        catchError((err: HttpErrorResponse) => {
          this.spinnerService.setIsSpinnerActive(false);
          throw err;
        })
      )
      const res: HttpResponse<any> = await lastValueFrom(source$);
      return res?.body
    }

    /**
   * PATCH Service
   * @param serviceUrl string
   * @param body any
   * @returns Promise<any>
   */
    public async patchService(serviceUrl: string, body?: any, isMocked = environment.mocked): Promise<any> {
      if (isMocked) {
        return new MockWebService(this.http, this.spinnerService).getMockResponse(serviceUrl);
      }
  
      this.spinnerService.setIsSpinnerActive(true);
  
      let url: string = this.BASE_URL;
      if (serviceUrl) {
        url = url.concat(serviceUrl);
      }

          //@ts-ignore
    let headers = new HttpHeaders({ Authorization: localStorage.getItem("ACCESS_TOKEN") || '' });
    //headers = headers.append("api-key", this.API_KEY);
    headers = headers.append("x-api-key", this.X_API_KEY);
    headers = headers.append("Content-Type", "application/json");
    //headers = headers.append("Access-Control-Allow-Origin", this.ACCESS_CONTROL_HEADER_ORIGINS)
  
      const source$ = this.http.patch<any>(url, JSON.stringify(body), { headers: headers }).pipe(
        tap(() => this.spinnerService.setIsSpinnerActive(false)),
        catchError((err: HttpErrorResponse) => {
          this.spinnerService.setIsSpinnerActive(false);
          throw err;
        })
      )
      const res: HttpResponse<any> = await lastValueFrom(source$);
      return res?.body
    }

}
