import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SDialogComponent } from './dialog.component';
import { PatchOperation } from 'src/app/modules/dashboard/view/pages/review-page/model/patch-operation.enum';
import { PatchObject } from 'src/app/modules/dashboard/view/pages/review-page/model/patch-object.model';
import { StorageService } from '../../services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { WebService } from '../../services/web-service/web.service';
import { ExceptionListService } from 'src/app/modules/dashboard/view/pages/exception-list-page/service/exception-list-page.service';
import { SnackbarService } from '../snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private storageService: StorageService, private webService: WebService, private exceptionListService: ExceptionListService, private snackbarService: SnackbarService) {}

  openDialog(exceptionDetails: any, table: any): void {
    const dialogData = {
      exceptionDetails: exceptionDetails,
      table: table,
      //isWarning: false,
      //isReject: false,
    }
    this.dialog.open(SDialogComponent, {
      width: '619px',
      height: '455px',
      data: dialogData,
    });
  }

  //PATCH Request________________________________________________________________________________________________
  setPatchObject(): PatchObject {
    return {
      userName: this.storageService.getUserUsername(),
      userFullName: this.storageService.getUserFullName(),
    };
  }

  async patchExceptionBE(
    patchObject: PatchObject,
    id: string,
    operation: PatchOperation
  ) {
    const testingMockBE = true;
    const path = !environment.mocked && testingMockBE  && this.storageService.getUserRole().toLowerCase() == 'engineer' ? `/v1/feature-audit/exception/${id}/action/${operation}` 
    : !environment.mocked && testingMockBE  && this.storageService.getUserRole().toLowerCase() == 'approver' ? `/v1/feature-audit/exception/${id}/workflow/${operation}`  //double check with be
    : '/patch/'
    this.webService
      .patchService(path, patchObject, (testingMockBE ? environment.mocked : true))
      .then((response) => {
        console.log('PATCH request successful, snackbar response', response);
        this.exceptionListService.setFilter(this.exceptionListService.filter);
        this.snackbarService.setIsSnackbarActive(true); //TODO:snackbar
      })
      .catch((error) => {
        console.log('PATCH request error', error);
      });
  }
}
