import { Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 's-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SPageFooterComponent {
  @Input() cssClass? = '';

  @Input() haveSecondary = true;
  @Input() disabledSecondary = false;
  @Input() cssClassSecondary? = '';
  @Input() labelSecondary = 'COMMON.BACK_BUTTON';
  @Input() typeSecondary:
    | 'basic'
    | 'raised'
    | 'stroked'
    | 'flat'
    | 'icon'
    | 'fab'
    | 'mini-fab'
    | 'fab-extended' = 'flat';
  @Input() iconSecondary?: string;

  @Input() havePrimary = true;
  @Input() disabledPrimary = false;
  @Input() cssClassPrimary? = '';
  @Input() labelPrimary = 'COMMON.CONFIRM_BUTTON';
  @Input() typePrimary:
    | 'basic'
    | 'raised'
    | 'stroked'
    | 'flat'
    | 'icon'
    | 'fab'
    | 'mini-fab'
    | 'fab-extended' = 'flat';
  @Input() iconPrimary?: string;

  @Output() clickEventSecondary: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() clickEventPrimary: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private router: Router) {
  }

}
