import { Injectable } from '@angular/core';
import { WebService } from 'src/app/shared/services/web-service/web.service';
import {
  ExceptionModelFE,
  ExceptionModelBE,
  ExceptionTableModel,
} from 'src/app/modules/dashboard/model/exception.model';
import { DashboardService } from 'src/app/modules/dashboard/service/dashboard.service';
import {
  BehaviorSubject,
  Observable,
  forkJoin,
  from,
  of,
  throwError,
} from 'rxjs';
import { map, switchMap, concatMap, catchError } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { HttpParams } from '@angular/common/http';
import { SelectInterface } from 'src/app/shared/components/select/select.component';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExceptionListService extends DashboardService {
  // Delete all "fake backend" variables when we have ready the real backend
  exceptionListFE!: Array<ExceptionTableModel>;
  tableColumns: SelectInterface[] = [];
  filter: string | null = 'mpad'; //this variable is used in the _getExceptionListBE() call to filter the tables
  private _filter: BehaviorSubject<string | null>; //this variable is used to set dynamic titles in the page-header when the filter changes
  private _notifications: BehaviorSubject<number>;
  isNewAdditions: boolean = false;
  tables = [];
  tableData = [];

  constructor(
    override webService: WebService,
    override storageService: StorageService,
    private dashboardService: DashboardService,
    private spinnerService: SpinnerService
  ) {
    super(webService, storageService);
    //this._getExceptionListBE(this.filter); //this manages FE the tables and their filters
    this._getTableColumns();
    this._filter = new BehaviorSubject<string | null>('mpad');
    this._notifications = new BehaviorSubject<number>(0);
    this.triggerGetTablesBE()
  }

  triggerGetTablesBE() {
    this._getTablesBE(this.filter, this.isNewAdditions).subscribe(
      (results: any) => {
        this.exceptionListFE = results;
      }
    );
  }

  getFilter() {
    return this._filter.asObservable();
  }

  setFilter(ecuFilter: string | null) {
    this._filter.next(ecuFilter);
    this.filter = ecuFilter;
    this.isNewAdditions = false; //remove to have combined filter new additions by ecu
    //this._getExceptionListBE(ecuFilter, this.isNewAdditions);
    this.triggerGetTablesBE()
  }

  getNotifications() {
    return this._notifications.asObservable();
  }

  showNewAdditions() {
    this.isNewAdditions = !this.isNewAdditions;
    this.dashboardService.setIsViewAllECUs(true);
    this._filter.next(''); //remove to have combined filter new additions by ecu
    this.filter = ''; //remove to have combined filter new additions by ecu
    //this._getExceptionListBE(this.filter, this.isNewAdditions);
    this.triggerGetTablesBE()
  }

  private async _getTableColumns() {
    await this.dashboardService.getDidListBE().then((response: {key: string; value: string}[]) => {
      this.tableColumns.push({ key: 'exception', value: 'EXCEPTION' });
      response.map((obj: { key: string; value: string }) => {
        let formattedObj = {
          key: obj.key.toUpperCase(),
          value: obj.value.toUpperCase(),
        };
        this.tableColumns.push(formattedObj);
      });
      this.tableColumns.push({ key: 'status', value: 'STATUS' });
    });
  }

  public _getTablesBE(
    ecuFilter: string | null,
    newAdditions: boolean
  ): Observable<any> {
    this.spinnerService.setIsExceptionListPageSpinnerActive(true);
    return new Observable((observer) => {
      // Create a new observable
      let params = new HttpParams();
      if (ecuFilter) {
        params = params.append('ecu', ecuFilter.toUpperCase());
      }
      const testingMockBE = true;
      const path =
        !environment.mocked && newAdditions && testingMockBE
          ? '/v1/feature-audit/exception/brands/pending'
          : !environment.mocked && !newAdditions && testingMockBE
          ? '/v1/feature-audit/exception/brands'
          : environment.mocked && newAdditions
          ? '/exception-brands-pending/'
          : '/exception-brands/';

      // const path = newAdditions
      //   ? '/exception-brands-pending/'
      //   : '/exception-brands/';
      this.webService
        .getService(path, params, undefined, (testingMockBE ? environment.mocked : true))
        .then((response: any) => {
          if (response.length > 0) {
            const sources = response.map(
              (element: {
                make: string;
                model: string;
                year: string;
                ecu: string;
              }) => {
                return this._getTableDataBE(
                  element.ecu,
                  element.make,
                  element.model,
                  element.year,
                  newAdditions
                );
              }
            );
            forkJoin(sources)
              .pipe(
                map((tableData: any) => {
                  const result = response.map((element: any, index: number) => {
                    return {
                      brand: {
                        ...element,
                        logo: this.dashboardService.getLogo(element.make),
                      },
                      exceptionList: tableData[index],
                    };
                  });
                  observer.next(result); // Emit the result to the observer
                  observer.complete(); // Complete the observable
                }),
                catchError((error) => {
                  //this.spinnerService.setIsSpinnerActive(false);
                  return throwError(() => new Error('test'));
                })
              )
              .subscribe({
                complete: () => {
                  this.spinnerService.setIsExceptionListPageSpinnerActive(false);
                },
              });
          } else {
            this.spinnerService.setIsExceptionListPageSpinnerActive(false);
            this.exceptionListFE = []
          }

        });
    });
  }

  private _getTableDataBE(
    ecuFilter: string,
    make: string,
    model: string,
    year: string,
    newAdditions: boolean
  ): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('ecu', ecuFilter);

    const testingMockBE = true;
    const path =
      !environment.mocked && newAdditions && testingMockBE
        ? `/v1/feature-audit/exception/get/${make}/${model}/${year}/pending`
        : !environment.mocked && !newAdditions && testingMockBE
        ? `/v1/feature-audit/exception/get/${make}/${model}/${year}`
        : environment.mocked && newAdditions
        ? '/exception-details-pending/'
        : '/exception-details/';

    // const path =
    //   environment.mocked && newAdditions
    //     ? '/exception-brands-pending/'
    //     : environment.mocked && !newAdditions
    //     ? '/exception-brands/'
    //     : !environment.mocked && newAdditions
    //     ? `/api/v1/feature-audit/exception/get/${make}/${model}/${year}/pending`
    //     : `/api/v1/feature-audit/exception/get/${make}/${model}/${year}`;

    // const path = newAdditions
    //   ? '/exception-details-pending/'
    //   : '/exception-details/';
    return from(
      this.webService.getService(path, params, undefined, (testingMockBE ? environment.mocked : true))
    ).pipe(
      map((response: any) => {
        let dids = this.dashboardService.getDidList();
        return response.map((element: any) => {
          dids = this.dashboardService.clearDidList();
          if (element.did.name.toUpperCase() in dids) {
            dids[element.did.name.toUpperCase()] = element.did.value ? element.did.value.toUpperCase() : "";
          }

          let historyData: {
            creation: string;
            addedByFullName: string;
            addedByUsername: string;
            approvedByFullName: string;
            approvedByUsername: string;
            enabled: string;
            disabled: string;
          } = {
            creation: '---',
            addedByFullName: '---',
            addedByUsername: '---',
            approvedByFullName: '---',
            approvedByUsername: '---',
            enabled: '---',
            disabled: '---',
          };
          element.history.map((object: any) => {
            if (object.action.toUpperCase() === 'CREATE') {
              historyData.creation = object.date;
              historyData.addedByFullName = object.userFullName;
              historyData.addedByUsername = object.userName;
            }
            if (object.action.toUpperCase() === 'APPROVE') {
              if(element.status.toLowerCase() == 'enabled') {
                historyData.enabled = object.date > historyData.enabled ? object.date : historyData.enabled;
                historyData.disabled = "---"
                historyData.approvedByFullName = object.userFullName;
                historyData.approvedByUsername = object.userName;
              } 
              if(element.status.toLowerCase() == 'disabled') {
                historyData.disabled = object.date > historyData.disabled ? object.date : historyData.disabled;
                historyData.enabled = "---"
                historyData.approvedByFullName = object.userFullName;
                historyData.approvedByUsername = object.userName;
              } 
            }
            //OLD LOGIC BASED ON PREVIOUS OBJECT & FLOW
              // if (object.action.toUpperCase() === 'REJECT') {
              //   historyData.enabled = '---';
              //   historyData.approvedByFullName = object.userFullName;
              //   historyData.approvedByUsername = object.userName;
              // }
              // if (object.action.toUpperCase() === 'APPROVE') {
              //   historyData.enabled =
              //     object.date > historyData.enabled
              //       ? object.date
              //       : historyData.enabled;
              //   historyData.approvedByFullName = object.userFullName;
              //   historyData.approvedByUsername = object.userName;
              // }
              // if ( //WIP NEW OBJ
              //   object.action.toUpperCase() === 'ENABLE' &&
              //   (object.date > historyData.disabled || historyData.disabled == '---')
              // ) {
              //   historyData.enabled = object.date;
              //   historyData.disabled = '---';
              //   historyData.approvedByFullName = object.userFullName;
              //   historyData.approvedByUsername = object.userName;
              // }
              // if ( //WIP NEW OBJ
              //   object.action.toUpperCase() === 'DISABLE' &&
              //   (object.date > historyData.enabled || historyData.enabled == '---')
              // ) {
              //   historyData.disabled = object.date;
              //   historyData.enabled = '---';
              //   historyData.approvedByFullName = object.userFullName;
              //   historyData.approvedByUsername = object.userName;
              // }
          });
          return {
            ...dids,
            ...historyData,
            id: element.id,
            exception: element.exceptionId,
            status:
              element.status == 'CREATED'
                ? 'Pending for approval - New addition'
                : element.history[0].action == 'REQUEST_DISABLE'
                ? 'Pending for approval - Disabled'
                : element.history[0].action == 'REQUEST_ENABLE'
                ? 'Pending for approval - Enabled'
                : element.history[0].action == 'REQUEST_CLOSE'
                ? 'Pending for approval - Closed'
                : element.status == 'REJECTED'
                ? 'Rejected'
                : element.status == 'CLOSED'
                ? 'Closed'
                : element.status == 'DELETED'
                ? 'Deleted'
                : element.status == 'ENABLED' && element.history[0].action != 'REQUEST_DISABLE' && element.history[0].action != 'REQUEST_CLOSE'
                ? 'Enabled'
                : element.status == 'DISABLED' && element.history[0].action != 'REQUEST_ENABLE' && element.history[0].action != 'REQUEST_CLOSE'
                ? 'Disabled'
                : '---',
            pending:
              element.status == 'CREATED'
                ? 'New addition'
                : element.status == 'PENDING'
                ? 'Edit request'
                : element.status == 'REJECTED'
                ? 'Rejected'
                : '---',
            details: element.details ? element.details : '',
            issueType: element.issueType,
          };
        });
      })
    );
  }

  //FE Filter Management_____________________________________________________________________________________

  // // Delete all "fake backend" methods when we have ready the real backend
  // private async _getExceptionDetailsBE(table: any) {
  //   let params = new HttpParams();
  //   params = params.append('ecu', table);
  //   //these params should point to the specific table.
  //   //Otherwise, map elements and insert condition that if (table.ecu == customTableHeaderConfig.ecu && make && model && model year) {return element.datasource}
  //   await this.webService
  //     .getService('/exception-list-fake-BE/', params, undefined, true)
  //     .then((response: any) => {
  //       return response.dataSource;
  //     });
  // }

  // private async _getExceptionListBE(
  //   ecuFilter: string,
  //   newAdditions?: boolean
  // ) {
  //   let params = new HttpParams();
  //   params = params.append('ecu', ecuFilter);
  //   params = params.append('pending', newAdditions ? newAdditions : false);
  //   await this.webService
  //     .getService('/exception-list-fake-BE/', params, undefined, true)
  //     .then((response: any) => {
  //       this.exceptionListFE = response
  //         //TODO Change FE filters with right parameters get data filtered from BE
  //         //ECU FE filter;
  //         .filter(
  //           (el: any) =>
  //             this.filter === 'view all' ||
  //             el.customTableHeaderConfig.ecu.toLowerCase() ===
  //               this.filter.toLowerCase()
  //         )
  //         //new additions FE filter p1;
  //         .filter(
  //           (el: any) =>
  //             !this.isNewAdditions ||
  //             el.dataSource.filter(
  //               (element: any) =>
  //                 element.pending.toLowerCase() === 'new addition'
  //             )
  //         )
  //         .map((el: any): ExceptionTableModel => {
  //           return {
  //             brand: {
  //               ...el.customTableHeaderConfig,
  //               logo: this.dashboardService.getLogo(
  //                 el.customTableHeaderConfig.make
  //               ),
  //             },
  //             exceptionList: el.dataSource
  //               //change with another BE call to get the details for each table if ECU make model year match
  //               //exceptionList: this._getTableDetailsBE(el)

  //               //new additions FE filter p2;
  //               .filter(
  //                 (el: any) =>
  //                   !this.isNewAdditions ||
  //                   el.pending.toLowerCase() === 'new addition'
  //               )

  //               .map((el: any): ExceptionModelFE => {
  //                 return {
  //                   ...el,
  //                   visible: false,
  //                 };
  //               }),
  //           };
  //         });
  //     });
  // }
}

// //ORIGINAL FE CALL
// private async _getExceptionListBE(
//   ecuFilter: string,
//   newAdditions?: boolean
// ) {
//   let params = new HttpParams();
//   params = params.append('ecu', ecuFilter);
//   params = params.append('pending', newAdditions ? newAdditions : false);
//   await this.webService
//     .getService('/exception-list-fake-BE/', params, true)
//     .then((response: any) => {
//       this.exceptionListFE = response
//         //TODO Change FE filters with right parameters get data filtered from BE
//         //ECU FE filter;
//         .filter(
//           (el: any) =>
//             this.filter === 'view all' ||
//             el.customTableHeaderConfig.ecu.toLowerCase() ===
//               this.filter.toLowerCase()
//         )
//         //new additions FE filter p1;
//         .filter(
//           (el: any) =>
//             !this.isNewAdditions ||
//             el.dataSource.filter(
//               (element: any) =>
//                 element.pending.toLowerCase() === 'new addition'
//             )
//         )
//         .map((el: any): ExceptionTableModel => {
//           return {
//             brand: { ...el.customTableHeaderConfig, logo: this.dashboardService.getLogo(el.customTableHeaderConfig.make)},
//             exceptionList: el.dataSource
//             //change with another BE call to get the details for each table if ECU make model year match
//             //exceptionList: this._getTableDetailsBE(el)

//               //new additions FE filter p2;
//               .filter(
//                 (el: any) =>
//                   !this.isNewAdditions ||
//                       el.pending.toLowerCase() === 'new addition'
//               )

//               .map((el: any): ExceptionModelFE => {
//                 return {
//                   ...el,
//                   visible: false,
//                 };
//               }),
//           };
//         });

//         let notificationCount: number = 0;
//         response.forEach((el: any) => {
//           el.dataSource.forEach(
//             (element: any) => {
//               if (element.pending.toLowerCase() === 'new addition') {
//                 notificationCount = notificationCount +1
//               }
//             }
//           )
//         });
//         this._notifications.next(notificationCount);
//     });
// }
