
<div class="dialog-content-container">

    <div class="dialog-header">
        <s-page-header
            [pageTitle]="pageTitle"
            [pageSubtitle]="pageSubtitle"
        ></s-page-header>
        <div>
            <img class="logo" [src]="logo" />
            <span class="dialog-make"> {{ data.table.brand.make}}</span>
        </div>
    </div>

    <div *ngIf="!isWarning">

        <div class="dialog-content">
            <div class="section">
                <div  *ngIf="data.exceptionDetails.status.toLowerCase() == 'enabled'">
                    <div class="dialog-content-item-status">
                        <img class="dialog-status-icon-check" src="/assets/icons/check-enabled.svg"/>
                        <span class="expandable-row-detail-title enabled"
                        >{{ "EXPANDABLE_ROW.ENABLED" | translate }}</span>
                    </div>
                    <span *ngIf="data.exceptionDetails.enabled !== '---'">{{
                        data.exceptionDetails.enabled | date : "dd.MM.yyyy"
                    }}</span>
                    <span *ngIf="data.exceptionDetails.enabled === '---'">{{ "---" }}</span>
                </div>

                <div *ngIf="data.exceptionDetails.status.toLowerCase() == 'disabled'">
                    <div class="dialog-content-item-status">
                        <img class="dialog-status-icon-check" src="/assets/icons/check-disabled.svg"/>
                        <span class="expandable-row-detail-title disabled"
                        >{{ "EXPANDABLE_ROW.DISABLED" | translate }}</span>
                    </div>
                    <span *ngIf="data.exceptionDetails.disabled !== '---'">{{
                        data.exceptionDetails.disabled | date : "dd.MM.yyyy"
                    }}</span>
                    <span *ngIf="data.exceptionDetails.disabled === '---'">{{ "---" }}</span>
                </div>
            </div>
        </div>

        <div class="dialog-content">

            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.MODELS" | translate }}</div>
                <div *ngFor="
                    let vehicle of dashboardService.getReviewObj().vehicles;
                    let i = index
                    ">
                    <span *ngFor="
                        let model of vehicle.models;
                        let i = index;
                        let lastItem = last
                        ">
                        <span>
                        {{ model.name | titlecase }}
                        <span
                            *ngIf="!lastItem"
                            style="margin-left: -3px; margin-right: 3px"
                            >,
                        </span>
                        </span>
                    </span>
                    <span *ngIf="!vehicle.models">--</span>
                </div>
            </div>

            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.MODEL_YEARS" | translate }}</div>
                <div
                    *ngFor="
                    let vehicle of dashboardService.getReviewObj().vehicles;
                    let i = index;
                    let lastVehicle = last
                    "
                >
                    <div
                    *ngFor="
                        let model of vehicle.models;
                        let i = index;
                        let lastModel = last
                    "
                    >
                    <div style="margin-right: 3px">
                        <span *ngIf="model && model.years">
                        <span
                            *ngFor="
                            let year of model.years;
                            let i = index;
                            let lastYear = last
                            "
                        >
                            <span>
                            {{ year | titlecase }}
                            <span *ngIf="!lastYear" style="margin-left: -3px"
                                >,
                            </span></span
                            >
                        </span>
                        </span>
                    </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="subtitle">
                <span *ngIf="storageService.getUserRole() == 'approver'">{{
                    "REVIEW_PAGE.SELECT" | translate
                }}</span>
                {{ "REVIEW_PAGE.ECU" | translate }}
                </div>
                <div *ngIf="dashboardService.getReviewObj().ecu">
                {{ dashboardService.getReviewObj().ecu | uppercase }}
                </div>
                <div *ngIf="!dashboardService.getReviewObj().ecu">--</div>
            </div>
        
            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.ISSUE_TYPE" | translate }}</div>
                <div *ngIf="dashboardService.getReviewObj().issueType">
                    <div
                    *ngFor="
                        let issue of dashboardService.getReviewObj().issueType;
                        let lastItem = last
                    "
                    >
                    <span> {{ issue | titlecase }} </span>
                    <span *ngIf="!lastItem" style="margin-left: -3px">, </span>
                    </div>
                </div>
                <div *ngIf="!dashboardService.getReviewObj().issueType">--</div>
            </div>

            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.ADDED_BY" | translate }}</div>
                <div
                *ngIf="dashboardService.getReviewObj().addedByFullName"
                style="color: black"
                >
                {{ dashboardService.getReviewObj().addedByFullName | titlecase }}
                </div>
                <div *ngIf="!dashboardService.getReviewObj().addedByFullName">--</div>
            </div>

        </div>

        <div class="dialog-content"> 
            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.DID" | translate }}</div>
                <div class="dids-container">
                <div *ngFor="let didKey of getDidsKeys(); let k = index">
                    <div *ngIf="dashboardService.getReviewObj().dids[didKey]">
                    <span> {{ didKey | uppercase }} </span>
                    <span> &#10132; </span>
                    <span>
                        {{ dashboardService.getReviewObj().dids[didKey] | uppercase }}
                    </span>
                    </div>
                </div>
            </div>
            </div>
        </div>


        <div class="dialog-content"> 
            <div class="section">
                <div class="subtitle">{{ "REVIEW_PAGE.DETAILS" | translate }}</div>
                <div *ngIf="dashboardService.getReviewObj().details">
                {{ dashboardService.getReviewObj().details | titlecase }}
                </div>
                <div *ngIf="!dashboardService.getReviewObj().details">--</div>
            </div>
        </div>

    </div>

    <div *ngIf="isWarning" class="dialog-warning-container">
        <div class="dialog-warning-title"> {{"SNACKBAR.CONFIRM_TITLE" | translate}} </div>
        <div class="dialog-warning-content"> {{"SNACKBAR.WARNING_CONTENT" | translate}} </div>
    </div>

    <s-page-footer
    [labelSecondary]="
      storageService.getUserRole() == 'approver' &&
      (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending')  && 
      !isWarning
        ? 'COMMON.REJECT_BUTTON'
        : 'COMMON.BACK_BUTTON'
    "
    [typeSecondary]=" 'fab-extended'"
    [cssClassSecondary]="
        storageService.getUserRole() == 'approver' &&
        (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending') &&
        !isWarning
        ? 'red-outline' 
        : ''
        "
    [iconSecondary]="
        storageService.getUserRole() == 'approver' &&
        (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending') &&
        !isWarning
        ? 'close'
        :'west'
    "
    (clickEventSecondary)="openCancel()"

    [labelPrimary]="
      storageService.getUserRole() == 'engineer' &&
      (dashboardService.getEcuStatus() == 'enabled' || dashboardService.getEcuStatus() == 'requestDisable')
        ? 'REVIEW_PAGE.DISABLE_EXCEPTION_BUTTON'
        : storageService.getUserRole() == 'engineer' &&
          (dashboardService.getEcuStatus() == 'disabled' || dashboardService.getEcuStatus() == 'requestEnable')
        ? 'REVIEW_PAGE.ENABLE_EXCEPTION_BUTTON'
        : storageService.getUserRole() == 'approver' &&
        isWarning && isReject
        ? 'REVIEW_PAGE.REJECT_BUTTON'
        : 'REVIEW_PAGE.CONFIRM_AND_APPROVE_BUTTON'
    "
    [typePrimary]="'fab-extended'"
    [cssClassPrimary]="
        storageService.getUserRole() == 'engineer' &&
        (dashboardService.getEcuStatus() == 'enabled' || dashboardService.getEcuStatus() == 'requestDisable') 
        ? 'red-outline' 
        : storageService.getUserRole() == 'engineer' &&
        (dashboardService.getEcuStatus() == 'disabled' || dashboardService.getEcuStatus() == 'requestEnable')
        ? 'green-outline' 
        : storageService.getUserRole() == 'approver' &&
        (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending')  &&
        isWarning && isReject
        ? 'red-outline'
        : storageService.getUserRole() == 'approver' &&
        (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending')
        ? 'green-outline'
        
        : ''
        "
    [iconPrimary]="
        storageService.getUserRole() == 'engineer' &&
        (dashboardService.getEcuStatus() == 'enabled' || dashboardService.getEcuStatus() == 'requestDisable') 
        ? 'close'
        : storageService.getUserRole() == 'engineer' &&
        (dashboardService.getEcuStatus() == 'disabled' || dashboardService.getEcuStatus() == 'requestEnable')
        ? 'check'
        : storageService.getUserRole() == 'approver' &&
        (dashboardService.getEcuStatus().includes('request') || dashboardService.getEcuStatus() == 'pending')  &&
        isWarning && isReject
        ? 'close'
        : 'check'
    "
    (clickEventPrimary)="openConfirm(dashboardService.getReviewObj())"
  ></s-page-footer>
</div>